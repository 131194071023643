@import "config/variables";
@import "config/mixins";

.cookie-modal {
  display: none;
}

.cookie-modal.active {
  display: inline-block;
  border: 1px solid #5e6d75;
  font-family: Lato, sans-serif;
  z-index: 101;
  width: 50%;
  height: 65%;
  position: fixed;
  top: 0;
  transform: translate(43%, 30%);
  background-color: #fff;
  color: #000;
  @media screen and (max-width: 1350px) {
    width: 80%;
    transform: translate(20%, 30%);
  }
  @media screen and (max-width: 1000px) {
    width: 80%;
    transform: translate(15%, 30%);
  }
  @media screen and (max-width: 840px) {
    width: 88%;
    transform: translate(6%, 30%);
  }
  @media screen and (max-width: 730px) {
    width: 96%;
    transform: translate(1%, 15%);
  }
  @media screen and (max-width: 690px) {
    width: 85%;
    z-index: 1000;
    transform: translate(9%, 12%);
  }

  .cookie-wrap {
    display: flex;
    flex-direction: row;
    height: 100% !important;
    background-color: #fff;
    @media screen and (max-width: 690px) {
      display: flex;
      flex-direction: column !important;
      height: 115% !important;
    }

    .cookie-menu {
      flex-basis: 32% !important;
      background-color: #5e6d75;
      @media screen and (max-width: 690px) {
        flex-basis: 80% !important;
      }
    }

    aside {
      position: relative;
      line-height: 1.3;
      font-size: 14px;
      text-align: justify;
      @media screen and (min-width: 691px) {
        padding: 10px 20px;
        flex-basis: 68% !important;
      }
      @media screen and (max-width: 690px) {
        position: inherit;
      }

      & .changable {
        @media screen and (max-width: 690px) {
          display: none !important;
        }
      }

      p {
        font-weight: 700;
        font-size: 16px;
        margin-top: 0 !important;
      }

      .buttons-wrap {
        position: absolute;
        left: 0;
        width: 100%;

        border-top: 1px solid #ddd;
        @media screen and (max-width: 690px) {
          position: fixed;
          height: 110px;
          background-color: #fff !important;
        }

        @media screen and (min-width: 691px) {
          height: 64px;
          bottom: 0;
        }

        button {
          background-color: #fff;
          color: #f48813;
          border: 1px solid #f48813;
          padding: 7px 15px;
          font-size: 14px;
          @media screen and (max-width: 690px) {
            width: 92%;
          }

          &:hover {
            background-color: #f48813;
            color: #fff;
          }
        }

        .allow-choose {
          position: absolute;
          top: 16px;
          left: 16px;
        }

        .allow-all {
          background-color: #f48813;
          color: #fff;
          border: 1px solid #fff;
          position: absolute;
          top: 16px;
          right: 16px;
          @media screen and (max-width: 690px) {
            right: auto;
            left: 16px;
            top: 60px;
          }

          &:hover {
            background-color: #fff;
            color: #f48813;
            border: 1px solid #f48813;
          }
        }
      }
    }
  }

  h4 {
    position: relative;
    font-size: 17px;
    border-bottom: #5e6d75 solid 1px;
    font-weight: bold;
    margin-bottom: 0px;
    @media screen and (max-width: 690px) {
      font-size: 15px;
    }

    img {
      padding: 16px;
      @media screen and (max-width: 690px) {
        padding: 9px;
      }
    }

    .close-x {
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
    }

    img {
      vertical-align: middle;
    }
  }

  .accordion-header {
    cursor: pointer;
    color: #fff;
    position: relative;
    padding: 10px 10px 10px 14px;
    border: #5e6d75 solid 1px;
    border-bottom: solid #fff 1px;
    font: inherit;
    font-size: 14px !important;

    &::after {
      content: ' ';
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      background-image: url('data:image/svg+xml; utf8, <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="25" height="25"><g><path xmlns:default="http://www.w3.org/2000/svg" d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" style="fill: rgb(255, 255, 255);" vector-effect="non-scaling-stroke"/></g></svg>');
      background-size: 20px 20px;
      right: 10px;
      @media screen and (max-width: 690px) {
        rotate: 90deg;
      }
    }

    &.active {
      background-color: #91a0a8;
      color: #fff;
      font-weight: bold;
      border: #5e6d75 solid 1px;
      @media screen and (max-width: 690px) {
        border-right: none;
        border-bottom: #5e6d75 solid 1px;
      }
    }
  }

  .accordion-body {
    display: none;
    @media screen and (max-width: 690px) {
      overflow-y: scroll !important;
      height: 0px;
      position: relative;
      display: inline-block;
      padding: 0 23px 13px 13px;
      background-color: #fff;
      margin-top: 0;
      line-height: 1.2;
      text-align: justify;

      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.7em;
        margin: 10px 0;
      }
    }
  }
}

#cookie-info-bar {
  background-color: rgba(94, 109, 117, 0.86);
  border: 0;
  bottom: 0;
  color: #fff;
  display: block !important;
  font-family: inherit;
  margin: 0 auto;
  padding: 21px 24px;
  position: fixed;
  text-align: justify;
  z-index: 100;
  font-size: 10pt !important;

  #cookie_content_wrap {
    @media screen and (min-width: 691px) {
      display: flex;
    }
  }

  #cookie_buttons_wrap {
    @media screen and (min-width: 691px) {
      justify-content: center;
      flex: 40%;
      display: inline-flex;
      margin-right: 2em;
      margin-left: 3em;
    }
  }

  & > p {
    @media screen and (min-width: 691px) {
      margin-right: 20px;
      flex: 70%;
    }
  }

  #cookie_action_close_header {
    padding: 10px;
    @media screen and (max-width: 690px) {
      width: 100%;
    }
    @media screen and (min-width: 691px) {
      line-height: 34px;
      width: 100%
    }
  }

  #cookie_action_settings {
    white-space: nowrap;
    padding: 10px !important;
    @media screen and (max-width: 690px) {
      width: 100%;
    }
    @media screen and (min-width: 691px) {
      max-height: 34px;
      width: 100%
    }
  }

  #cookie_action_close_header {
    background-color: #f48813 !important;
  }

  #cookie_action_settings {
    background-color: transparent !important;
  }

  #cookie_action_close_header, #cookie_action_settings {
    cursor: pointer;
    border: 1px solid #fff !important;
    border-radius: 0%;
    font-size: 14px;
    text-align: center;
    @media screen and (max-width: 690px) {
      margin: 10px 0;
    }
    @media screen and (min-width: 691px) {
      margin: 10px;
      max-height: 34px;
      padding: 0 25px;
    }

  }

  #cookie_action_close_header:hover, #cookie_action_settings:hover {
    box-sizing: border-box !important;
    border: 1px solid #fff;
  }

  #cookie_action_close_header:hover {
    background-color: transparent !important;
  }

  #cookie_action_settings:hover {
    background-color: #f48813 !important;
  }

  .cli-plugin-button,
  .cli-plugin-button:visited, #cookie_action_settings, #cookie_action_settings:visited {
    background: none !important;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    font-family: inherit !important;
    -webkit-box-shadow: none !important;
  }

  .cli-plugin-button,
  .cli-plugin-button:visited,
  .medium.cli-plugin-button,
  .medium.cli-plugin-button:visited {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
  }

  .media-min(@lg, {
    padding: 10px 0;
    & p {
      line-height: 1.7;
      padding: 0 40px;
    }
  });
}

.accordion-header.active::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
}

.cookie-checkbox {
  position: absolute;
  top: 5px;
  right: 0px;
  pointer-events: all;
  cursor: pointer;
  z-index: 9999;
  @media screen and (max-width: 690px) {
    top: 23px;
  }
}

.cookie-checkbox.active::after {
  content: ' ';
  display: inline-block;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #999;
  background-size: 20px 20px;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  @media screen and (max-width: 690px) {
    top: -2px;
  }
}

.cookie-checkbox.active.clicked::after {
  content: ' ';
  display: inline-block;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #00a1d9;
  background-size: 20px 20px;
  border-radius: 50%;
  left: auto;
  right: 0;
  top: 0px;
  @media screen and (max-width: 690px) {
    top: -2px;
  }
}

.wide-body {
  margin-right: 20px;
}

button {
  border: 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
}

#cookie_content_wrap p {
  margin: 0em;
  line-height: 1.7em;
}
